<template>
  <ClientOnly>
    <div ref="observableNode" :class="containerClass" class="popup">
      <Popper
        :class="`minilu-popper ${customPopperClass}`"
        arrow
        hover
        :placement="isMobileScreen ? 'bottom' : 'right-start'"
        :offset-skid="isMobileScreen ? '0' : '-8px'"
        :offset-distance="offsetDistance"
      >
        <button type="button">
          <FaIcon
            icon-class="fad fa-circle-info"
            style="
              --fa-primary-color: var(--minilu-font);
              --fa-secondary-color: var(--minilu-orange);
            "
          />
        </button>
        <template #content="{ close }">
          <div ref="observableNodeContent" class="content">
            <slot />
            <button
              v-if="isMobileScreen"
              type="button"
              class="text-white ml-md r-xms"
              @click="close"
            >
              <FaIcon icon-class="fas fa-xmark" />
            </button>
          </div>
        </template>
      </Popper>
    </div>
  </ClientOnly>
</template>
<script setup lang="ts">
// Wrapped in Client-Only due to SSR-Errors => https://github.com/valgeirb/vue3-popper/issues/56
import Popper from 'vue3-popper';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import FaIcon from '@/components/fa-icon.vue';

const isMobileScreen = inject(mq_breakpointIsMobile);

/** Props */
defineProps({
  containerClass: {
    type: String,
    required: false,
    default: '',
  },
  customPopperClass: {
    type: String,
    required: false,
    default: '',
  },
  offsetDistance: {
    type: String,
    required: false,
    default: '0',
  },
});
</script>
<style scoped lang="postcss">
:deep(.popper) {
  @apply md:!w-auto !max-w-md !py-sm md:!py-md !px-md !rounded-minilu-lg;
}
.popup {
  @apply ml-xs;
}

.content {
  @apply flex items-start;
}
</style>
